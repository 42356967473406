<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">部门</div>
					<div class="inputs_box">
						<bumen_cascader ref="bumen_cascader" :multiple="false" :is_show_position="false"
						@confirm="confirm_bumen_cascader_value" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">时间</div>
					<div class="inputs_box">
						<el-date-picker v-model="forms.month" value-format="yyyy-MM" type="month" placeholder="选择月" clearable :editable="false"
							:picker-options="pickerOptions">
						</el-date-picker>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box" v-loading="loading">
			<div class="bom_type_flex">
				<div class="tabs-box">
					<span v-for="(item,index) in tabs" :key="index" @click="getDataList(item)"
						:class="{active:forms.status==item.status}">
						{{item.title}}
					</span>
				</div>
				<div class="btns_flex">
					<div class="btns" v-if="forms.status==10&&user_info.id==2" @click="handle_ad" >新增下发</div>
				</div>
			</div>


			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" >

				
					<el-table-column prop="gz_date" label="月份" width="100"></el-table-column>
					<el-table-column prop="title" label="工资标题"></el-table-column>
					<el-table-column prop="depart.title" label="部门"></el-table-column>
					<el-table-column prop="gw_num" label="定岗人数"></el-table-column>
					<el-table-column prop="num" label="工资人数"></el-table-column>
					<el-table-column prop="payable_gz" label="应发总额"></el-table-column>
					<el-table-column prop="actual_gz" label="实发总额"></el-table-column>
					<el-table-column prop="money" label="">
						<template slot-scope="scope">
							<div class="">
								<div class="" v-html="scope.row.money"></div>
							</div>
						</template>
					</el-table-column>
					<!-- 1 -->
					<el-table-column prop="xf_at" label="下发时间" width="200"></el-table-column>
					<!-- 2 -->
					<el-table-column prop="sb_at" label="上报时间" width="200"></el-table-column>
					<!-- 3 -->
					<el-table-column prop="sh_at" label="审核时间" width="200" ></el-table-column>

					<el-table-column label="操作" fixed="right"  width="250">
						<template slot-scope="scope">
							<el-button type="text" size="small" class="btn_modify" @click="watch_money(scope.row)">查看人员工资</el-button>
							<el-button type="text" size="small" class="btn_modify" @click="handle_dy(scope.row)">部门工资表(打印)</el-button>
							<el-button type="text" size="small" class="btn_modify" v-if="forms.status==10" @click="handle_xf(scope.row)">下发</el-button>
							<el-button type="text" size="small" class="btn_modify" v-if="forms.status==20" @click="handle_sb(scope.row)">上报</el-button>
							<el-button type="text" size="small" class="btn_modify" v-if="forms.status==30" @click="handle_shtg(scope.row)">审核</el-button>
						</template>
					</el-table-column>
					 
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


		<!--  -->
		<addSalary ref="addSalary" />

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的

	import addSalary from "@/components/salary/addSalary.vue"; //新增员工标准工资

	export default {
		components: {
			page,
			addSalary,
		},
		data() {
			return {
				user_info:{},

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					status:10,//10待下发 20待上报 30待审核 40已确定
					month:'',//时间
					// depart_id:'',//部门id
				},

				tabs: [
					{
						title: '工资待下发',
						status: 10,
					},
					{
						title: '工资待上报',
						status: 20,
					},
				
					{
						title: '工资待审核',
						status: 30,
					},
					{
						title: '工资已确定',
						status: 40,
					},
				],

				list: [
				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围


				//
				month_select: '',
				bm_list_ac:[],
				//字段
				pickerOptions: {
					disabledDate(time) {
						console.log('time', time)
						return (time.getMonth()) > new Date().getMonth();
					}
				},
			};
		},

		methods: {
			//权限判断
			get_user() {
				// this.NavigateItem = []
				console.log(localStorage.getItem('cache_user'))
				this.loading = true
				let data={}
				if(localStorage.getItem('cache_user')){
					data=JSON.parse(localStorage.getItem('cache_user'))
				}
				this.$api("getAdminRole", {
					id:data.role_id
				}, "get").then((res) => {
					this.loading = false
					console.log('角色信息',res)
					if (res.code == 200) {
						 this.user_info=res.data

					} else {
						alertErr(res.msg)
					}
				});

			},

			// 状态
			getDataList(item) {

				this.forms.status = item.status
				this.pages.page=1
				this.get_list()
				// 刷新接口 分页为1
			},
			handle_ad(){
				this.$router.push({
					path:'/Salary_distribution_add',
				})
			},
			// 查看人员工资
			watch_money(row) {
				let time=row.gz_date.replace('年','-').replace('月','')
				this.$router.push({
					path: '/Employee_salary_list',
					query:{
						time:time,
						id:row.depart.id,
						title:row.depart.title,
						send_id:row.id,
						state:this.forms.status
					}
				})
			},


			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				let depart_id=''
				if(this.bm_list_ac.length>0){
					depart_id=this.bm_list_ac[this.bm_list_ac.length-1]
				}
				this.$api("empMonthSalary", {
					// mobile:this.mobile
					...this.forms,
					depart_id:depart_id,
					...this.pages,
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},
			//打印
			handle_dy(){

			},
			handle_xf(row){
				this.$confirm('是否要下发工资?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api("empMonthAudit", {
						id:row.id,
						status:20,
					 
					}, "post").then((res) => {
						this.loading = false
						if (res.code == 200) {
							this.get_list()
						} else {
							alertErr(res.msg)
						}
					});
					
				}).catch(() => {
							
				});
			},
			handle_sb(row){
				this.$confirm('是否要上报工资?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api("empMonthAudit", {
						id:row.id,
						status:30,
					 
					}, "post").then((res) => {
						this.loading = false
						if (res.code == 200) {
							this.get_list()
						} else {
							alertErr(res.msg)
						}
					});
					
				}).catch(() => {
							
				});
			},
			handle_shtg(row){
				this.$confirm('是否要审核通过该工资?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api("empMonthAudit", {
						id:row.id,
						status:40,
					 
					}, "post").then((res) => {
						this.loading = false
						if (res.code == 200) {
							this.get_list()
						} else {
							alertErr(res.msg)
						}
					});
					
				}).catch(() => {
							
				});
			},
			 
			confirm_bumen_cascader_value(value, bumen_items, bumen_titles) {
				// this.$log('勾选部门数据', value)
				this.bm_list_ac=value
			},
		 


		 

		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
			this.get_user()
		 
		},
		mounted() {

		}


	};
</script>

<style scoped lang="less">
	.tabs-box {
		margin-top: 20px;
		display: flex;

		span {
			// font-size: ;
			padding: 17px 0;
			font-size: 14px;
			color: #333;
			position: relative;
			margin-right: 38px;
			cursor: pointer;

			&.active {
				color: #2373C8;
				font-weight: bold;

				&::after {
					content: '';
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					height: 1px;
					background-color: #2373C8;

				}
			}
		}

	}


	.filter-box {
		padding: 30px 20px;
		padding-bottom: 0;

		.filter-group {
			margin-bottom: 10px;
			display: flex;
			align-items: flex-start;

			.filter-label {
				min-width: 100px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
			}

			.filter-items {
				flex: 1;
				overflow: hidden;

				.el-checkbox {
					margin-bottom: 20px;
				}
			}
		}
	}



	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;
			.bom_type_flex{
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #EDF0F6;
				padding: 0 33px;
				.btns_flex{
					.btns{
						width: 100px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}
					
				}
			}

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				// height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>